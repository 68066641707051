.blacklist-user-dialog__close {
  position: absolute;
  right: 1rem;
  top: 1rem;
  outline: none;
  border: none;
  background-color: transparent;
  cursor: pointer;
}

.blacklist-user-dialog__block {
  background: linear-gradient(225.95deg, #d64848 0%, #b33232 100%) !important;
}

.blacklist-user-dialog__unblock {
  background: linear-gradient(225.95deg, #48d6b6 0%, #32b396 100%) !important;
}

.blacklist-user-wrapper {
  margin-left: auto;
}

.blacklist-user-dialog__button {
  height: 4rem;
  padding: 1rem 4rem !important;
  color: #fff !important;
}

.blacklist-user-dialog {
  position: relative;

  > div {
    > div {
      padding: 2rem;
      padding-top: 3.2rem;
    }
  }

  > div:nth-child(2) {
    padding: srem;
    padding-top: 4rem;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .error-text {
    text-align: center;
    margin-top: 0.5rem;
  }

  form {
    input {
      margin-top: 1.6rem;
    }
    button {
      margin-top: 1.6rem;
      font-size: 1.8rem;
      background: linear-gradient(
        180deg,
        rgba(99, 180, 245, 1) 0%,
        rgba(1, 126, 229, 1) 100%
      );
      color: #fff;
    }
  }
}

.blacklist-user-dialog__loading {
  text-align: center;
  > div:nth-child(2) {
    padding-top: 1rem;

    > div {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-evenly;

      > div:first-child {
        margin-bottom: 2rem;

        > div:nth-child(2) {
          margin-top: 0.5rem;
        }
      }
    }
  }
}
