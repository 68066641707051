@import '../../../styles/settings.scss';

.details__header {
  display: flex;
  justify-content: space-between;
  position: relative;
  width: 100%;
  padding: 1.5rem 6rem 1.5rem 2rem;
  background: linear-gradient(
    90deg,
    rgba(1, 126, 229, 0.8) 0%,
    rgba(99, 180, 245, 0.8) 40%
  );
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;

  .details__header__su-confirmation {
    position: absolute;
    right: 2rem;
    top: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 3rem;
    height: 3rem;
    color: #fff;
    border: 3px solid #fff;
    border-radius: 50%;
    background: $green-2;

    svg {
      width: 1.8rem;
    }
  }

  > div:first-child {
    display: flex;
    flex-direction: column;
  }

  p {
    margin: 0;
    opacity: 0.8;
    color: #ffffff;
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;

    span {
      font-size: 2rem;
      line-height: 2.4rem;
    }

    .high-risk {
      color: #ff0000;
    }

    .low-risk {
      color: #00ff00;
    }

    &:last-of-type {
      margin-top: auto;
    }
  }

  h2 {
    margin: 1.7rem 0 0;
    color: #ffffff;
    font-size: 3.6rem;
    font-weight: 500;
    line-height: 4.3rem;

    & + p {
      margin-top: 0;
    }
  }

  .details__header__avatar {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    min-width: 10.5rem;
    max-width: 10.5rem;
    min-height: 10.5rem;
    max-height: 10.5rem;
    margin-top: auto;
    border-radius: 50%;
    background: #fff;

    > svg {
      color: #ff0000;
      opacity: 0.5;
      font-size: 5rem;
    }

    img {
      min-width: 10.5rem;
      max-width: 10.5rem;
      min-height: 10.5rem;
      max-height: 10.5rem;
      border: 2px solid #fff;
      border-radius: 50%;
      object-fit: cover;
    }

    .details__header__avatar__initials {
      font-size: 4.2rem;
      color: rgba(1, 126, 229, 0.7);
    }
  }

  .details__header__status {
    position: absolute;
    left: -1rem;
    bottom: 0;
    width: 3.3rem;
    height: 3.3rem;
    border: 3px solid #fff;
    border-radius: 50%;

    &.invalid {
      background-color: #ff0000;
    }
    &.valid {
      background-color: #00ff00;
    }
  }

  .details__header__avatar__badge {
    position: absolute;
    right: -1rem;
    bottom: -0.5rem;
    display: block;
    width: 3.4rem;
    height: 4.1rem;

    span {
      position: absolute;
      top: 50%;
      left: 50%;
      color: #fff;
      transform: translate(-50%, -50%);
      font-size: 1.3rem;
    }

    &.details__header__avatar__badge--tier0 {
      background: url('../../../assets/badge-0.svg') no-repeat center;
    }

    &.details__header__avatar__badge--tier1 {
      background: url('../../../assets/badge-1.svg') no-repeat center;
    }

    &.details__header__avatar__badge--tier2 {
      background: url('../../../assets/badge-2.svg') no-repeat center;
    }
  }
}
