@import '../../../styles/settings.scss';

.user-orders__amount {
  display: flex;
  flex-direction: column;
  flex: 0;
  > span:first-child {
    font-weight: 500;
  }

  > span:last-child {
    color: $textSecondary;
    font-size: 1.3rem;
    font-weight: 500;
  }
}
