@import '../../../styles/settings.scss';

.history__filter-by {
  height: 6.4rem;
  display: flex;
  align-items: center;
  padding: 1rem 1.7rem;

  > span {
    color: $textSecondary;
    font-size: 1.6rem;
    white-space: nowrap;
    margin-right: 0.4rem;
    padding-bottom: 2px;
  }
}
