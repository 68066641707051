@import '../../../styles/settings.scss';

.compliance-documents {
  display: flex;
  flex-wrap: wrap;

  > div {
    min-width: 50%;
    max-width: 50%;

    &:last-child {
      display: flex;
      flex-wrap: wrap;

      > div {
        min-width: 50%;
        max-width: 50%;
      }
    }
  }
}

.compliance-documents__item {
  .compliance-documents__item--saved {
    padding-top: 2rem;
    justify-content: center;
    display: flex;
    align-items: center;
    font-size: 1.8rem;
  }
  margin-bottom: 1.5rem;

  > span:first-child {
    display: block;
    width: 100%;
    margin-bottom: 0.4rem;
    opacity: 0.5;
    font-size: 1.2rem;
    line-height: 1.4rem;
  }

  > span:nth-child(2) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    font-size: 1.6rem;
    line-height: 1.9rem;

    &.invalid {
      color: $textRed;
    }

    &.capitalize {
      text-transform: capitalize;
    }

    svg {
      margin-right: 4rem;

      &.valid {
        color: #32b396;
      }

      &.invalid {
        color: $textRed;
        transform: rotate(45deg);
      }
    }
  }
}

.compliance-documents__actions {
  display: flex;
  align-items: center;
  margin-bottom: 3rem;
  margin-top: -1rem;
  font-size: 1.4rem;

  .compliance-documents__actions__radio-group {
    flex-direction: row;
    > label {
      > span {
        margin-right: 0.5rem;
        font-size: 1.4rem;
      }
    }

    > label:first-child {
      margin-left: 3rem;
    }
  }

  > button {
    background: linear-gradient(
      180deg,
      rgba(99, 180, 245, 0.1) 0%,
      rgba(1, 126, 229, 0.1) 100%
    );
    color: #1f8eea;
    padding: 1.2rem 2.5rem;
    font-size: 1.6rem;
    width: 15rem;
  }
}

.compliance-documents__compliance-mrz {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;

  > div {
    min-width: calc(85% / 3);
    max-width: calc(85% / 3);
    margin-bottom: 2rem;
    margin-right: calc(15% / 3);
  }

  button {
    transform: translateY(1rem);
  }
}

.compliance-documents__compliance-mrz__no-scan-run {
  color: $textSecondary;
  font-style: italic;
}

.compliance-documents__actions,
.compliance-documents__compliance-mrz {
  button {
    background: linear-gradient(
      180deg,
      rgba(99, 180, 245, 0.1) 0%,
      rgba(1, 126, 229, 0.1) 100%
    );
    color: #1f8eea;
    padding: 1.2rem 2.5rem;
    font-size: 1.6rem;
    min-width: 15rem;
  }
}
