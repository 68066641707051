@import '../../../styles/settings.scss';

.compliance__comments {
  > form {
    display: flex;
    margin-bottom: 0.6rem;
    align-items: center;

    > div:first-child {
      max-width: 36rem;
      width: 100%;
      margin-right: 4rem;
    }
  }

  .compliance__comments__list {
    margin-top: 2.4rem;
    > div {
      margin-bottom: 1.2rem;
    }
  }

  .compliance__comments-no-comments {
    margin-top: 2.4rem;
    color: $textSecondary;
    font-style: italic;
  }

  .compliance__comments__error {
    color: $red-1;
    font-size: 1.2rem;
  }
}
