@import '../../../styles/utils.scss';
@import '../../../styles/settings.scss';

.user-orders__table__error {
  margin: 2rem;
  color: $red-1;
  font-size: 1.8rem;
  text-align: center;
}

.user-orders__table {
  width: 100%;
  margin-top: 3.2rem;

  .user-orders__table-head {
    font-size: 1.4rem;
    color: $textSecondary;
    font-weight: 500;
    margin-bottom: 1rem;
  }

  .user-orders__table-row {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 5rem;
  }

  .--center {
    text-align: center;
  }

  .--date {
    min-width: 7rem;
    max-width: 7rem;
    text-align: center;
  }

  .--user {
    min-width: 32%;
    max-width: 32%;

    &.--sell {
      margin-left: 1.5rem;
    }
  }

  .--amount {
    min-width: 7%;
    max-width: 7%;
    text-align: right;
  }

  .--status {
    min-width: 13%;
    max-width: 13%;
    text-align: center;
  }

  .--payment {
    min-width: 15%;
    max-width: 15%;
    text-align: left;
  }

  .--mint {
    min-width: 8%;
    max-width: 8%;
    // text-align: center;
  }

  .--alert {
    min-width: 5%;
    max-width: 5%;
    text-align: center;

    img {
      cursor: pointer;
    }
  }
  .--actions {
    position: absolute;
    right: 2rem;
    min-width: 3rem;
    max-width: 3rem;
  }

  .--source {
    min-width: 6%;
    max-width: 6%;
    text-align: center;

    img {
      width: 1.7rem;
    }
  }

  .--fee {
    min-width: 4%;
    max-width: 4%;
    color: $textSecondary;
    font-weight: 500;
    text-align: center;
  }

  .user-orders__table-body {
    @extend .paper;
    padding: 1rem 0 2rem;

    .user-orders__table-row {
      &:not(:last-child) {
        margin-bottom: 0.5rem;
      }
    }

    .--fee {
      font-size: 1.3rem;
    }
  }
}
