@import '../../../styles/settings.scss';

.history__search-box {
  display: flex;
  padding: 1rem 0;
  flex: 1;

  > button {
    outline: none;
    border: none;
    padding: 0 1.7rem;
    border-right: 2px solid $borderGray;
    background-color: $white;

    &:hover {
      cursor: pointer;
    }

    > svg {
      font-size: 3rem;
      opacity: 0.4;
    }
  }

  > div {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  > div:last-child {
    padding: 0 1.7rem;
    display: inline-flex;

    input {
      font-size: 1.6rem;
    }
  }
}
