@import '../../../styles/settings.scss';

.compliance__users-list-block {
  margin: 3rem 1.6rem 1.6rem 1.6rem;

  display: flex;
  align-items: flex-start;

  .compliance__users-list-block__icon {
    margin-right: 3.2rem;
    font-size: 2.8rem;
    height: 4.5rem;
    display: flex;
    align-items: center;
    padding-bottom: 0.5rem;
    width: 2.7rem;

    // letter
    > span {
      text-transform: uppercase;
      color: #ff0000;
      opacity: 0.5;
      width: 2.7rem;
      text-align: center;
    }
  }

  .compliance__users-list-block__list {
    flex: 1;
  }
}

.compliance__users-list__user {
  cursor: pointer;
  display: flex;
  align-items: center;

  &:not(:last-child) {
    margin-bottom: 2.5rem;
  }

  .compliance__users-list__name {
    &__selected {
      color: $selectedBlue;
    }
  }
}

.compliance__users-list__avatar {
  position: relative;
  width: 4.5rem;
  height: 4.5rem;
  border-radius: 50%;
  margin-right: 1.5rem;

  &::after {
    content: '';
    border-radius: 50%;
    box-shadow: inset 0 0 0.5rem rgba(0, 0, 0, 0.15);
    display: block;
    height: 100%;
    position: absolute;
    top: 0;
    width: 100%;
  }

  > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
  }

  .compliance__users-list__avatar--initials {
    color: #ff0000;
    opacity: 0.5;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    font-weight: 500;
  }

  .compliance__users-list__avatar--icon {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;

    svg {
      color: #ff0000;
      opacity: 0.5;
      font-size: 3rem;
      padding-bottom: 2px;
    }
  }
}
