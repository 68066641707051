.compliance-download-btn {
  margin-top: 0.5rem;
  font-size: 1.2rem;
  line-height: 1.4rem;
  outline: none;
}

.compliance-download-btn__container {
  display: inline-flex;
  flex-direction: column;

  .error-text {
    margin-top: 0.3rem;
  }
}
