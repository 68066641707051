.compliance__checklist {
  .compliance__checklist__item {
    display: flex;
    align-items: center;
    font-size: 1.6rem;

    &:not(:last-child) {
      margin-bottom: 0.5rem;
    }

    > svg {
      margin-right: 0.5rem;
    }
  }
}
