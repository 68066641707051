@import '../../../styles/settings.scss';

.comliance-user-attr {
  flex-direction: column;
  margin-bottom: 1rem;

  > span {
    margin-bottom: 0 !important;
  }

  > span:nth-child(2) {
    margin-top: 0.5rem;
  }

  .comliance-user-attr__wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
  }

  .comliance-user-attr__error {
    min-width: 100%;
    margin-top: 1rem;
    color: $red-1;
    font-size: 1.4rem;
  }

  .comliance-user-attr-item {
    display: flex;
    justify-content: space-between;
    align-items: center;

    > span {
      margin: 0;
      opacity: 1;
    }

    > span:first-child {
      position: relative;
      margin-right: 3rem;
      color: black;
      font-size: 1.7rem;

      > div {
        position: absolute;
        right: -2rem;
        top: 0;
      }
    }
  }
}
