@import '../../../styles/settings.scss';

.check-videos {
  margin-right: 1rem;
  svg {
    width: 3.25rem;
    fill: $primary-main;
    height: 3.25rem;
  }
}
