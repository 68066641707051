.compliance__action-buttons {
  padding: 0 4rem;
  margin-top: 5rem;
  display: flex;
  justify-content: space-around;

  > button {
    width: 100%;
    padding: 1.6rem 3.2rem;
    font-size: 1.6rem;
    line-height: 1.9rem;
    font-weight: 500;
    color: #fff;
  }

  > button:last-child {
    background: linear-gradient(225.95deg, #d64848 0%, #b33232 100%);
  }

  > button:first-child {
    margin-right: 4.4rem;
    background: linear-gradient(225.95deg, #48d6b6 0%, #32b396 100%);
  }

  > btn:only-child {
    display: inline-flex;
  }
}
.compliance__action-buttons__loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  > div:first-child {
    margin-bottom: 2rem;
  }
}

.compliance__action-buttons__confirm-box {
  padding: 2rem;
  width: 44rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 3rem;

  &__message {
    font-size: 1.8rem;
    margin-bottom: 1.6rem;
  }

  &__buttons {
    display: flex;
    margin-top: auto;

    > button:first-child {
      background: linear-gradient(
        180deg,
        rgba(99, 180, 245, 1) 0%,
        rgba(1, 126, 229, 1) 100%
      );
      color: #fff;
      width: 100%;
    }

    > button:last-child {
      background: linear-gradient(
        180deg,
        rgba(99, 180, 245, 0.1) 0%,
        rgba(1, 126, 229, 0.1) 100%
      );
      color: #1f8eea;
      width: 100%;
      margin-left: 2rem;
    }
  }

  .error-text {
    margin-top: 0.5rem;
    text-align: center;
  }

  .user-details-bottom__checklist-item {
    display: flex;
    align-items: center;
    > svg {
      margin-right: 0.5rem;
    }
  }

  .compliance__action-buttons__checklist {
    margin-bottom: 1rem;
  }
}
