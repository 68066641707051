.compliance__pep-check {
  width: 100%;

  .compliance__pep-check__result {
    margin-bottom: 0.6rem;
  }

  .tier2-form__file-input__container {
    max-width: 39rem;
  }

  .compliance__pep-check__radio {
    margin-top: 2rem;
    display: flex;
    align-items: center;

    > div:first-child {
      margin-right: 6rem;
    }

    .compliance__pep-check__radio-group {
      flex-direction: row;
      > label {
        > span {
          margin-right: 0.5rem;
          font-size: 1.6rem;
        }
      }

      > label:first-child {
        margin-right: 3rem;
      }
    }
  }
}
