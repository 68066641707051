.user-orders__payment {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  .user-orders__date {
    margin-right: 1.5rem;
    padding-right: 2rem;
  }
}
