.compliance__user-verification {
  width: 100%;

  > div:first-child {
    max-width: 39rem;
    textarea {
      &::placeholder {
        white-space: nowrap;
      }
    }
  }

  > div:last-child {
    display: flex;
    justify-content: space-between;
  }

  .tier2-form__file-input__container {
    max-width: 39rem;
  }

  .compliance__user-verification__radio {
    margin-top: 2rem;
    display: flex;
    align-items: center;

    > div:first-child {
      margin-right: 6rem;
    }

    &-group {
      flex-direction: row;
      > label {
        > span {
          margin-right: 0.5rem;
          font-size: 1.6rem;
        }
      }

      > label:first-child {
        margin-right: 3rem;
      }
    }

    button {
      margin-left: auto;
      background: linear-gradient(
        180deg,
        rgba(99, 180, 245, 0.1) 0%,
        rgba(1, 126, 229, 0.1) 100%
      );
      color: #1f8eea;
      width: 20rem;
      padding: 1.2rem 2.5rem;
      font-size: 1.6rem;
    }
  }
}

.compliance__user-verification__done {
  > div:first-child {
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;

    > svg {
      margin-left: 0.5rem;
    }
  }
}
