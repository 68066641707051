.additional-clarifications {
  flex-wrap: wrap;
  margin-top: 5rem;

  .tier2-form__file-input__container {
    max-width: 39rem;
  }

  .compliance__risk__inputs {
    width: 100%;

    > div {
      display: flex;
      align-items: center;
      justify-content: space-between;

      &:not(:last-child) {
        margin-bottom: 2rem;
      }

      > div {
        flex: 1;
      }
    }
  }

  .compliance__risk__action {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin-top: 2rem;

    button {
      margin-left: auto;
      background: linear-gradient(
        180deg,
        rgba(99, 180, 245, 0.1) 0%,
        rgba(1, 126, 229, 0.1) 100%
      );
      color: #1f8eea;
      width: 20rem;
      padding: 1.2rem 2.5rem;
      font-size: 1.6rem;
    }
  }
}
