.compliance {
  display: flex;
  justify-content: space-between;
  margin-top: -4.5rem;

  .compliance__users {
    min-width: 35%;
    max-width: 35%;
    position: relative;
    z-index: 1;

    > div:first-child {
      margin-bottom: 3rem;
    }

    .compliance__users__top {
      display: flex;

      .refresh-button {
        margin-right: 2.7rem;
      }
    }
  }

  .compliance__no-users {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 2.1rem;

    display: flex;
    align-items: center;

    > span:first-child {
      margin-right: 1rem;
    }
  }
}
