// colors
$headerGray: '#F9F9FC';
$backgroundGray: #fcfcfc;
$white: #fff;
$selectedBlue: #1f8eea;
$textBlack: #01152c;
$textRed: #ff0000;
$red-1: #ff2525;
$green-1: #3bff00;
$green-2: #32b396;
$yellow-light: #f9f6df;
$green-light: #ecfaf7;
$red-light: #ffeded;
$headerGrey: #f7f8fb;
$lightGrey: #ededed;

$primary-light: #6bafe6;
$primary-light-2: #e4eefa;
$primary-light-3: #308fee;
$primary-light-4: rgba(234, 245, 253, 1);

$primary-main: #308fee;
$primary-dark: #208fea;
$primary-dark-2: #316d9c;

// shadows
$box-shadow-1: 0 5px 6px 0 rgba(0, 0, 0, 0.05);

// border-colors
$borderGray: rgba(1, 21, 44, 0.1);

// gradients

$gradient-red-1: linear-gradient(225.95deg, #ff2525 0%, #fd6767 100%);
$gradient-secondary: linear-gradient(
  180deg,
  rgba(99, 180, 245, 0.1) 0%,
  rgba(1, 126, 229, 0.1) 100%
);

// text colors
$text-light-grey-1: rgba(1, 21, 44, 0.5);
$text-light-grey-2: rgba(1, 21, 44, 0.75);
$text-light-grey-3: rgba(1, 21, 44, 1);
$text-light-grey-4: rgba(1, 21, 44, 0.2);
$textSecondary: rgba(0, 0, 0, 0.54);
$textBlack: #01152c;
$lightGrey: #ededed;
