@import '../../../styles/settings.scss';

.compliance-personal-info {
  .compliance-personal-info__section {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;

    &.--bank-section {
      align-items: flex-end;
    }

    &.--documents-section {
      .compliance-documents {
        display: flex;
        justify-content: space-between;
        width: 100%;

        > div {
          min-width: 40%;
          max-width: 40%;
        }
      }
    }

    &:not(:last-child) {
      border-bottom: 1px solid $borderGray;
    }

    .compliance-personal-info__section-row {
      display: flex;
      flex-direction: row;
      width: 100%;
      justify-content: space-between;

      &.--alt {
        .compliance-personal-info__item {
          max-width: none;
          min-width: 0;
          width: auto;
        }
      }

      .compliance-personal-info__item {
        margin-right: 0;
        width: 100%;
      }

      .compliance-personal-info__account-address {
        display: flex;

        > div:first-child {
          flex: 1;
          min-width: auto;
          max-width: none;
        }

        > div:nth-child(2) {
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-left: 1rem;
        }
      }

      .compliance-personal-info__item {
        margin-right: 0;
        min-width: 0;
      }
    }

    .compliance-personal-info__account-address {
      display: flex;
      justify-content: space-between;
    }
  }

  .compliance-personal-info__item {
    max-width: 40%;
    min-width: 40%;
    margin-bottom: 2.5rem;
    color: $textBlack;
    font-size: 1.6rem;
    line-height: 1.9rem;

    &.--center {
      text-align: center;
    }

    .compliance-personal-info__image {
      height: 2.4rem;
      width: 2.4rem;
    }

    &.--alt {
      min-width: 100%;
      max-width: 100%;
    }

    &.--bank-item {
      display: flex;
      align-items: center;

      svg {
        margin-right: 0.5rem;
      }
    }

    .--account {
      font-size: 1.3rem;
      word-break: break-word;
    }

    > span:first-child {
      display: block;
      width: 100%;
      margin-bottom: 0.4rem;
      opacity: 0.5;
      font-size: 1.2rem;
      line-height: 1.4rem;
    }

    > span:nth-child(2) {
      display: flex;
      align-items: center;

      span {
        margin-right: 0.5rem;
      }
    }
  }
}
