@import '../../../styles/settings.scss';

.confirmation-by-gl {
  color: $green-1;

  &.--alt {
    color: $red-1;
  }
}

.compliance-desk {
  .--risk {
    justify-content: flex-start;
    span {
      margin-left: 1rem;
    }
  }
}

.compliance__save-result__button {
  margin-left: auto;
  position: relative;

  button {
    background: linear-gradient(
      180deg,
      rgba(99, 180, 245, 0.1) 0%,
      rgba(1, 126, 229, 0.1) 100%
    );
    color: #1f8eea;
    width: 20rem;
    padding: 1.2rem 2.5rem;
    font-size: 1.6rem;
  }

  .error-text {
    text-align: center;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    margin-top: 0.3rem;
  }
}
