@import '../../../styles/utils.scss';

.compliance__user-details {
  @extend .paper;
  min-width: 62%;
  max-width: 62%;
  padding-bottom: 1.7rem;

  .dropdown {
    .dropdown__header {
      font-weight: 500;
    }
    &:last-child {
      border-bottom: none;

      .dropdown__content {
        padding-bottom: 1rem;
      }
    }
  }

  h3 {
    min-width: 100%;
    margin: 1rem 0 1.4rem;
    color: $textBlack;
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
  }

  .compliance-btn {
    display: inline-block;
    padding: 1rem 2rem;
    border: 1px solid #979797;
    cursor: pointer;
  }
}

.compliance__user-details__error {
  min-width: 62%;
  max-width: 62%;
  padding-bottom: 1.7rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2rem !important;
}

.user-details__section {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;

  > div {
    width: 100%;
  }

  &:not(:last-child) {
    padding-bottom: 2.5rem;
    margin-bottom: 1.4rem;
    border-bottom: 1px solid $borderGray;
  }
}
