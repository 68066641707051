@import './settings.scss';

.wrapper {
  max-width: 120rem;
  margin: 0 auto;

  &.user-error {
    position: relative;
    top: -6rem;
    padding: 0 1rem;
  }
}

.paper {
  background-color: $white;
  box-shadow: $box-shadow-1;
  border-radius: 10px;
  overflow: hidden;
}

.error-text {
  color: $red-1;
  font-size: 1.3rem;
}

.align-center {
  text-align: center;
}
