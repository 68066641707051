@import '../../../styles/settings.scss';

.compliance-fee {
  span {
    margin-right: 3rem;
  }
}

.fee-dialog {
  > div {
    > div {
      padding: 2rem;
      padding-top: 3.2rem;
    }
  }

  .fee-dialog__close {
    position: absolute;
    right: 1rem;
    top: 1rem;
    outline: none;
    border: none;
    background-color: transparent;
    cursor: pointer;
  }

  .element-form__input-wrapper {
    margin-bottom: 2rem;
  }

  .fee-dialog__button {
    background: $gradient-secondary;
  }

  .error-text {
    margin-top: 1rem;
  }
}
