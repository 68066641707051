@import '../../styles/settings.scss';

.styled-button {
  display: flex;
  justify-content: space-between;
}

.styled-button-disabled {
  opacity: 0.5;
  > span {
    color: $textSecondary;
  }
}
