@import '../../../styles/settings.scss';

.user-risk-evaluation {
  position: relative;

  strong {
    font-weight: 500;
  }

  > div {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    max-width: 50rem;
    margin-bottom: 1.5rem;

    > button {
      position: absolute;
      right: -8rem;
      top: -0.5rem;
    }

    > p {
      width: 100%;
    }

    &:first-child {
      border-bottom: 2px solid rgba(1, 21, 44, 0.1);
    }
  }

  .compliance__risk__files {
    justify-content: flex-start;
    margin-top: 3rem;
    margin-bottom: 0;

    button {
      position: relative;
      margin: 0 1rem 1rem 0;
      left: 0;
    }
  }

  .user-risk-profile-evaluation__column {
    h5 {
      width: 100%;
      margin: 0;
      color: $textSecondary;
      font-weight: 400;
      font-size: 1.2rem;
      margin-bottom: 1.1rem;
    }

    display: flex;
    flex-direction: column;

    > span {
      margin-bottom: 0.7rem;
    }

    &:last-child {
      align-items: flex-end;
    }
  }

  .overall-risk {
    margin-top: 1.5rem;
  }

  .high {
    color: $red-1;
  }

  .medium {
    color: orange;
  }

  .low {
    color: $green-1;
  }

  .--capitalize {
    text-transform: capitalize;
  }
}
