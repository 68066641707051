.update-risk-level {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 30rem;
  padding: 2rem;

  > div {
    margin-bottom: 3rem;
  }

  > button {
    background: linear-gradient(
      180deg,
      rgba(99, 180, 245, 0.1) 0%,
      rgba(1, 126, 229, 0.1) 100%
    );
    color: #1f8eea;
    padding: 1.2rem 2.5rem;
    font-size: 1.6rem;
  }

  .error-text {
    text-align: center;
    margin-top: 0.5rem;
    margin-bottom: 0;
  }
}
