@import url('https://fonts.googleapis.com/css?family=Rubik:300,400,500');
@import './settings.scss';

html {
  height: 100%;
  font-size: 62.5%;
}

body {
  background-color: $backgroundGray;
  position: relative;
  margin: 0;
  font-size: 16px;
  font-family: 'Rubik', sans-serif;
  min-height: 100%;
  padding-bottom: 3rem;
  overflow: scroll;
}

#root {
  background-color: $backgroundGray;
  max-width: 140rem;
  margin: 0 auto;
}

body > #root > div {
  flex: 1;
}

a {
  text-decoration: none;
}

input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}
