@import '../../../styles/settings';

.activity-table-status-wrapper {
  display: inline-flex;

  .activity-table-status {
    display: inline-flex;
    flex: 1;
    padding: 0.3rem;
    padding-right: 1rem;
    border-radius: 4px;

    &__icon {
      border-right: 1px solid rgba(1, 21, 44, 0.1);
      margin-right: 0.5rem;
      padding: 0.3rem;
    }

    &__text {
      text-transform: uppercase;
      font-size: 1.4rem;
      display: flex;
      align-items: center;
    }

    &__complete {
      background-color: $green-light;
    }

    &__processing,
    &__triggered,
    &__accepted,
    &__default {
      background-color: $yellow-light;
    }

    &__on-hold {
      background-color: $red-light;
    }
    &__released {
      background-color: rgba(192, 214, 244, 0.5);
    }

    &__open {
      background-color: $headerGrey;
    }
  }

  > span {
    display: flex;
    align-items: center;
    margin-left: 0.5rem;
    padding: 0 0.5rem;
    background-color: $headerGrey;
    color: $primary-main;
    border-radius: 4px;
    line-height: 1;
    cursor: pointer;

    svg {
      width: 2rem;
    }
  }
}
