@import '../../../styles/settings.scss';

.compliance-status {
  padding: 1.5rem 2rem 1rem;
  border-bottom: 1px solid rgba(1, 21, 44, 0.1);

  .compliance-status__icons {
    flex-direction: row;
  }

  div > span {
    margin-bottom: 0.4rem;
    opacity: 0.5;
    font-size: 1.2rem;
    line-height: 1.4rem;
  }

  > div {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    &:first-child {
      align-items: center;
    }

    &:first-child {
      margin-bottom: 2rem;
    }

    > div {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    &.compliance-status__info {
      justify-content: flex-start;

      > div:nth-child(2),
      > div:nth-child(3) {
        margin-left: 4rem;
      }
    }

    &.compliance-fee {
      justify-content: flex-start;
      align-items: center;

      span {
        margin-right: 3rem;
        opacity: 1;
        font-size: 1.4rem;
      }
    }
  }

  .compliance-status__current-process {
    text-transform: capitalize;
  }

  .compliance-status__address {
    > div {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      &:not(:first-child) {
        font-size: 1.4rem;
      }
    }

    span:nth-child(2) {
      margin-left: auto;
    }

    img {
      width: 1.8rem;
      margin-left: 3rem;
      transform: translateX(-0.3rem);
    }
  }

  .compliance__user-details_limit.--infin {
    span {
      font-size: 3rem;
    }
  }

  .compliance-status__restore {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 3.2rem;
    height: 3.2rem;
    background: linear-gradient(
      90deg,
      rgba(1, 126, 229, 0.8) 0%,
      rgba(99, 180, 245, 0.8) 100%
    );
    color: #fff;
    border-radius: 50%;
    border: none;
    outline: none;
    cursor: pointer;

    svg {
      transform: translate(-0.1rem);
    }
  }

  .compliance-status__indicator {
    display: inline-block;
    width: 2.1rem;
    height: 2.1rem;
    border-radius: 50%;

    &.--active {
      background-color: $green-1;
    }

    &.--inactive {
      background-color: $red-1;
    }
  }

  .compliance__status__limit {
    span {
      font-size: 4rem;
    }
  }
}
