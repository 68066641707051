@import '../../../styles/settings.scss';

.compliance__history {
  position: relative;
  width: 70rem;
  padding: 2rem;

  h2 {
    margin: 0 0 2rem;
    padding: 0 2rem 2rem 0;
    border-bottom: 2px solid rgba(1, 21, 44, 0.1);
    line-height: 1;
    font-weight: normal;
  }

  > svg {
    position: absolute;
    right: 2rem;
    top: 2.3rem;
    z-index: 20;
    color: rgba(1, 21, 44, 0.2);
    cursor: pointer;
  }

  .compliance__history-no-history {
    color: $textSecondary;
    font-style: italic;
  }

  .compliance__history__row {
    display: flex;
    justify-content: space-between;
    font-size: 1.2rem;
    line-height: 1.4rem;

    &:not(:last-child) {
      margin-bottom: 1.5rem;
    }

    &.--alt {
      color: rgba(1, 21, 45, 0.5);
    }

    > div:first-child {
      min-width: 17%;
      max-width: 17%;
    }

    > div:nth-child(2) {
      min-width: 40%;
      max-width: 40%;

      span {
        display: block;
        margin-top: 0.5rem;
        color: rgba(1, 21, 45, 0.5);
      }

      .compliance__checklist {
        margin-top: 1rem;

        .compliance__checklist__item {
          color: rgba(1, 21, 45, 0.5);
          font-size: 1.2rem;
        }

        svg {
          width: 2rem;
          height: 2rem;
        }
      }
    }

    > div:last-child {
      min-width: 20%;
      max-width: 20%;
      span {
        text-transform: capitalize;
      }
    }
  }
}
