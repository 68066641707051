@import '../../../styles/settings.scss';

.user-orders__date {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-right: 1.5rem;
  border-right: 1px solid $lightGrey;

  > span:first-child {
    font-weight: 500;
    font-size: 1.2rem;
    text-transform: uppercase;
  }

  > span:last-child {
    font-weight: 400;
    font-size: 2.2rem;
    margin: -0.5rem;
  }
}
