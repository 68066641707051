@import '../../styles/settings.scss';

.dropdown {
  margin-top: 2rem;
  border-bottom: 1px solid $borderGray;

  .dropdown__header {
    display: flex;
    align-items: center;
    padding: 0 2rem 0.5rem;
    color: #01152c;
    font-size: 16px;
    line-height: 19px;
    cursor: pointer;

    > span:last-child {
      margin-left: auto;
      transition: all 0.3s;
    }

    &.--active {
      > span:last-child {
        transform: rotate(180deg);
      }
    }
  }

  .dropdown__content-wrapper {
    transition: height 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    overflow: hidden;
  }

  .dropdown__content {
    padding: 1.4rem 2rem 4rem;
  }
}
