@import '../../../styles/settings.scss';

.tier2-form__file-input__container {
  > p {
    margin-top: 0.5rem;
    margin-bottom: 0;
    color: $red-1;
    font-size: 1.2rem;
    line-height: 1.4rem;
  }

  .tier2-form__file-input {
    display: flex;
    position: relative;
    padding: 1.4rem 5rem 1.4rem 1.6rem;
    background: #fff;
    border-radius: 4px;
    border: 1px solid rgba(1, 21, 44, 0.1);
    color: $text-light-grey-1;
    overflow: hidden;
    cursor: pointer;

    input {
      display: none;
    }

    &.error {
      .tier2-form__file-input__progress {
        span {
          background: $gradient-red-1;
        }
      }
    }

    .tier2-form__file-input__upload-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 50%;
      right: 1.5rem;
      transform: translateY(-50%);
      z-index: 5;
      width: 3.3rem;
      height: 3.3rem;
      background: $gradient-secondary;
      border-radius: 50%;
      cursor: pointer;

      i {
        color: $primary-light-3;
        font-size: 1.6rem;
      }
    }

    .tier2-form__file-input__body {
      display: flex;
      align-items: center;
      position: relative;
      color: $text-light-grey-1;
      font-size: 1.8rem;
      line-height: 2.2rem;

      span {
        padding-left: 1.4rem;
        color: $text-light-grey-4;
      }

      i {
        color: $text-light-grey-4;
      }
    }

    .tier2-form__file-input__progress {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 5;
      height: 4px;
      background: $primary-light-4;

      span {
        display: block;
        height: 4px;
        background: $primary-main;
        box-shadow: 0 5px 10px 0 rgba(53, 155, 236, 0.2);
        transition: all 0.3s;
      }
    }

    &.active {
      .tier2-form__file-input__body {
        span {
          padding-left: 1.4rem;
          color: $text-light-grey-3;
          text-decoration: underline;
        }

        i {
          color: $primary-main;
        }
      }
    }
  }
}
