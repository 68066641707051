@import '../../../styles/settings.scss';

.mrz-data__item {
  .mrz-data__item--saved {
    padding-top: 2rem;
    justify-content: center;
    display: flex;
    align-items: center;
    font-size: 1.8rem;
  }
  margin-bottom: 1.5rem;

  > span:first-child {
    display: block;
    width: 100%;
    margin-bottom: 0.4rem;
    opacity: 0.5;
    font-size: 1.2rem;
    line-height: 1.4rem;
  }

  > span:nth-child(2) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    font-size: 1.6rem;
    line-height: 1.9rem;

    &.invalid {
      color: $textRed;
    }

    &.capitalize {
      text-transform: capitalize;
    }

    svg {
      // margin-right: 4rem;

      &.valid {
        color: #32b396;
      }

      &.invalid {
        color: $textRed;
        transform: rotate(45deg);
      }
    }
  }
}

.mrz {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 3rem;
  margin-top: -1rem;
  font-size: 1.4rem;
  width: 100%;

  .mrz__scan-button {
    display: flex;
    flex-direction: column;
    button:first-child {
      margin-bottom: 1.5rem;
    }
  }

  > div {
    display: flex;
    align-items: center;
  }

  > div:nth-child(2) {
    display: flex;
    flex-direction: column;
    width: 20rem;
    position: relative;

    > button {
      background: linear-gradient(
        180deg,
        rgba(99, 180, 245, 0.1) 0%,
        rgba(1, 126, 229, 0.1) 100%
      );
      color: #1f8eea;
      padding: 1.2rem 2.5rem;
      font-size: 1.6rem;
      width: 20rem;
    }

    > span {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: -2rem;
      width: 20rem;
      text-align: center;
    }
  }

  .mrz__radio-group {
    flex-direction: row;
    > label {
      > span {
        margin-right: 0.5rem;
        font-size: 1.4rem;
      }
    }

    > label:first-child {
      margin-left: 3rem;
    }
  }
}

.mrz-data {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;

  > div {
    min-width: calc(85% / 3);
    max-width: calc(85% / 3);
    margin-bottom: 2rem;
  }

  button {
    transform: translateY(1rem);
    width: 20rem;
  }
}

.mrz-data__save-button {
  position: relative;
  > span {
    position: absolute;
    font-size: 1.4rem !important;
    left: 75%;
    bottom: -1rem;
    transform: translateX(-50%);
  }
}

.mrz-data__no-scan-run {
  color: $textSecondary;
  font-style: italic;
}

.mrz,
.mrz-data {
  button {
    background: linear-gradient(
      180deg,
      rgba(99, 180, 245, 0.1) 0%,
      rgba(1, 126, 229, 0.1) 100%
    );
    color: #1f8eea;
    padding: 1.2rem 2.5rem;
    font-size: 1.6rem;
    min-width: 15rem;
  }
}

.mrz__download-external-documents {
  .compliance-download-btn__container {
    margin-right: 1rem;
  }
}

.mrz-container {
  .compliance__risk__inputs,
  .compliance__risk__action {
    max-width: 50rem;
  }
}
