@import '../../styles/settings.scss';

.status-icon {
  height: 2.3rem;
  width: 2.3rem;
  &.status-icon__valid {
    color: $green-2;
  }

  &.status-icon__invalid {
    color: $red-1;
    transform: rotate(45deg);
  }
}
