.add-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3.2rem;
  height: 3.2rem;
  background: linear-gradient(
    90deg,
    rgba(1, 126, 229, 0.8) 0%,
    rgba(99, 180, 245, 0.8) 100%
  );
  color: #fff;
  border-radius: 50%;
  border: none;
  outline: none;
  cursor: pointer;
}
