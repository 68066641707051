@import '../../../styles/settings.scss';

.admin-bank-balance {
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  line-height: 1.5;

  .admin-bank-balance__title,
  .admin-bank-balance__update {
    color: $text-light-grey-1;
  }

  .admin-bank-balance__title,
  .admin-bank-balance__value {
    font-size: 1.6rem;
  }

  .admin-bank-balance__title {
  }
  .admin-bank-balance__value {
  }

  .admin-bank-balance__update {
    font-size: 1.4rem;
  }

  .error-text {
    margin: 0;
  }
}
