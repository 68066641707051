.user-orders {
  margin-top: -5rem !important;

  .user-orders__tabs {
    margin-bottom: 3rem;

    button {
      span {
        font-size: 1.6rem;
        line-height: 1.9rem;
        font-weight: 500;
      }
    }
  }

  .user-orders-panel {
    display: none;

    &.--active {
      display: block;
    }
  }
}
