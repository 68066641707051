.refresh-button {
  border: none;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 6.4rem;
  height: 6.4rem;
  cursor: pointer;
  transition: all 0.2s;

  &:hover {
    background-color: darken(#fff, 7);
  }

  > svg {
    font-size: 3.5rem;
    color: #1f8eea;
  }
}

.refresh-button--active {
  @keyframes spin {
    from {
      transform: rotate(0deg);
    }

    to {
      transform: rotate(-359deg);
    }
  }
  > svg {
    animation: spin 0.5s;
  }
}
