@import '../../../styles/settings.scss';

.compliance__info-message {
  > div:first-child {
    font-size: 1.2rem;
    line-height: 1.4rem;
    color: $textSecondary;
  }

  > div:last-child {
    font-size: 1.2rem;
    margin-top: 0.5rem;
  }
}
