.token-details {
  display: flex;
  align-items: center;
  max-height: 11.4rem;

  .token-details__image {
    margin-right: 2.8rem;
  }

  .token-details__symbol {
    font-size: 1.8rem;
    font-weight: 500;
    line-height: 2.2rem;
    margin-right: 3rem;
  }

  .token-details__name {
    width: 8rem;
    margin-right: 3.2rem;
  }

  .token-details__address {
    text-align: right;
    justify-self: flex-end;
  }

  .token-details__total-supply {
    margin-left: auto;
    margin-right: 2rem;
  }
}
