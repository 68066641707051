@import '../../../styles/settings.scss';

.company-details {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 2.3rem;
  padding: 3rem;
  font-size: 1.8rem;
  line-height: 2.2rem;

  img {
    margin-right: 2.5rem;
  }

  .company-details__account {
    display: flex;
    flex-direction: column;
    color: $text-light-grey-1;

    span {
      color: $textBlack;
    }
  }

  .company-details__balance {
    display: flex;
    margin-left: auto;

    .company-details__balance__item {
      display: flex;
      flex-direction: column;
      color: $textBlack;
      min-width: 15rem;

      span {
        color: $text-light-grey-1;
        font-size: 1.2rem;
        line-height: 1.4rem;
      }
    }

    > div:not([class]) {
      display: flex;
      justify-content: flex-end;
      min-width: 15rem;

      > div {
        min-width: auto;
      }
    }
  }

  .company-details__loader {
    display: flex;
    justify-content: center;
    min-width: 100%;
  }

  .administration__error {
    margin: 0;
  }
}
