@import '../../../styles/settings.scss';

.user-orders__controls {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 5rem;

  > div {
    display: flex;

    &:nth-child(2) {
      flex: 1;
      margin: 0 1rem 0 2rem;
    }
    &:nth-child(3) {
      margin: 0 2rem 0 1rem;
    }
  }

  .user-orders__control__reset {
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translate(-50%, 1rem);
    background-color: #fff;
    box-shadow: $box-shadow-1;
    color: $textSecondary;
    padding: 0.3rem 0.5rem;
    border-radius: 4px;
    font-size: 1.2rem;
    bottom: -2.6rem;
    margin: 0;
    outline: none;
    border: none;
    cursor: pointer;

    &:active {
      outline: none;
    }

    &:hover {
      background-color: darken(#fff, 3);
    }
  }
}
