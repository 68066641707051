@import '../../../styles/settings.scss';

.token-panel {
  &--EUR36 {
    background-image: url('../../../assets/EUR36.svg');
    background-size: contain;
    background-position: right;
    background-repeat: no-repeat;
  }

  &--USD36 {
    background-image: url('../../../assets/USD36.svg');
    background-size: contain;
    background-position: right;
    background-repeat: no-repeat;
  }

  &--GBP36 {
    background-image: url('../../../assets/GBP36.svg');
    background-size: contain;
    background-position: right;
    background-repeat: no-repeat;
  }

  &--CHF36 {
    background-image: url('../../../assets/CHF36.svg');
    background-size: contain;
    background-position: right;
    background-repeat: no-repeat;
  }

  display: flex;

  .token-panel__left {
    width: 50%;
    padding: 1rem;

    > div {
      margin-bottom: 1rem;
    }
  }

  .token-panel__right {
    width: 50%;
    padding: 1rem;
    padding-left: 2rem;

    border-left: 1px solid lighten($borderGray, 50);
  }

  .token-panel__transfers {
    display: flex;
    align-items: center;

    > div {
      margin-right: 3.2rem;
    }
  }

  .token-panel__edit-field {
    display: flex;
    align-items: center;
    justify-content: space-between;

    > div:first-child {
      > div:first-child {
        color: $textSecondary;
      }
    }
  }
}
