@import '../../../styles/settings.scss';

.node-details__online {
  background: linear-gradient(226.34deg, #63b4f5 0%, #017ee5 100%);
}

.node-details__offline {
  background: linear-gradient(226.34deg, #f56363 0%, #e50101 100%);
}

.node-details {
  margin-top: -4rem;
  padding: 3rem 3rem;
  margin-bottom: 2.3rem;
  max-height: 11.4rem;
  color: $white;
  height: 100%;

  display: flex;
  justify-content: space-between;

  &.--error {
    > div:first-child {
      align-items: center;
    }
  }

  > div:first-child {
    display: flex;
    .node-details__image {
      margin-right: 2.9rem;
    }

    .node-details__name {
      color: rgba(255, 255, 255, 0.8);
      font-weight: 500;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      > div:first-child {
        font-size: 1.8rem;
        line-height: 2.2rem;
      }

      > div:last-child {
        font-size: 1.4rem;
        line-height: 1.7rem;
      }
    }
  }

  .node-details__field {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-weight: 500;

    > div:first-child {
      font-size: 1.2rem;
      line-height: 1.4rem;
    }

    > div:last-child {
      font-size: 1.8rem;
      line-height: 2.2rem;
    }

    &:last-child {
      text-align: right;
    }
  }

  .node-details__offline-text {
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 2rem;
    line-height: 2.4rem;
    color: $textBlack;
    margin-right: 10rem;
  }
}

@keyframes rotating {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.node-details__loading {
  background: linear-gradient(226.34deg, #63b4f5 0%, #017ee5 100%);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  > img {
    margin-right: 2.7rem;
    animation: rotating 0.5s linear infinite;
  }
}
