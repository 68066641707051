.new-token {
  .dropdown {
    margin-top: 0;
    .dropdown__header {
      position: relative;
      z-index: 1;

      padding: 3.8rem 3rem;

      > span:first-child {
        width: 100%;
      }

      > span:last-child {
        margin-left: auto;
      }
    }

    .dropdown__content {
      padding-bottom: 1rem;
    }
  }

  margin-bottom: 2.3rem;
}
